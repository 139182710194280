import { useAtsCandidateDiscoveryApi } from '@/composables/useApi';
import type { PlacePrediction } from './types/place-prediction.type';
import { useSearchStore } from './search.store';
import { useMeStore } from '@/core/shared/me/me.store';
import type { PaginatedResponse } from './types/paginated-response.type';
import type { SearchGatedUserProfile } from './types/search-gated-user-profile.type';
import { SelectedFilterType } from './types/selected-filter-type.type';
import { DEFAULT_AROUND_RADIUS } from './consts/default-seach-query.const';

interface BackendSelectedFilter {
  id?: number;
  value: string | number;
  type: SelectedFilterType;
}

export class SearchPersistence {
  private store = useSearchStore();
  private meStore = useMeStore();

  public async getAddressPredictions(address: string): Promise<PlacePrediction[]> {
    const { data, error } = await useAtsCandidateDiscoveryApi(
      `/geolocation/address-predict?address=${address}`,
    ).json<PlacePrediction[]>();

    if (error.value) {
      throw new Error(error.value);
    }

    if (!data.value) {
      throw new Error('No data');
    }

    return data.value;
  }

  public async searchCandidatesInAlgolia(): Promise<PaginatedResponse<SearchGatedUserProfile>> {
    const filtersRecord = this.store.selectedFiltersRecord;
    const aroundRadius =
      filtersRecord[SelectedFilterType.AROUND_RADIUS]?.value || DEFAULT_AROUND_RADIUS;
    const hitsPerPage = this.store.pagination.itemsPerPage || undefined;
    const location = filtersRecord[SelectedFilterType.LOCATION]?.value || undefined;
    const page = this.store.pagination.page - 1;
    const query = filtersRecord[SelectedFilterType.KEYWORD]?.value || '';

    const filters = this.store.selectedFilters.filter((f) =>
      [
        SelectedFilterType.BRAND,
        SelectedFilterType.JOB_TITLE,
        SelectedFilterType.KNOWLEDGE_DISCIPLINE,
        SelectedFilterType.LAST_ACTIVE,
        SelectedFilterType.OBJECT_ID,
        SelectedFilterType.TECHNOLOGY,
        SelectedFilterType.YEARS_OF_EXPERIENCE,
      ].includes(f.type),
    );

    const mappedFilters: BackendSelectedFilter[] = filters.reduce((acc, filter) => {
      const toAdd = [];

      // The backend requires the value to be in the `id` property.
      if (filter.type === SelectedFilterType.LAST_ACTIVE) {
        toAdd.push({ type: filter.type, id: filter.value, value: filter.value });
      }

      // The backend requires a separate filter for each years of experience.
      if (filter.type === SelectedFilterType.YEARS_OF_EXPERIENCE) {
        const filters = filter.value.map((v) => ({ type: filter.type, id: v, value: v }));
        toAdd.push(...filters);
      }

      if (toAdd.length === 0) {
        const value = typeof filter.value === 'number' ? filter.value : `${filter.value}`;

        toAdd.push({
          ...filter,
          value,
        });
      }

      return [...acc, ...toAdd];
    }, [] as BackendSelectedFilter[]);

    const search = {
      location,
      aroundRadius,
      page,
      hitsPerPage,
      query,
      filters: mappedFilters,
    };

    const employerId = this.meStore.employer?.id;
    const url = `/search/employer/${employerId}/candidates`;

    const { data, error } = await useAtsCandidateDiscoveryApi(url)
      .post(search)
      .json<PaginatedResponse<SearchGatedUserProfile>>();

    if (error.value || !data.value) {
      throw new Error(error.value);
    }

    return data.value;
  }

  public async searchCandidatesInTypesense(): Promise<PaginatedResponse<SearchGatedUserProfile>> {
    const filtersRecord = this.store.selectedFiltersRecord;
    const aroundRadius =
      filtersRecord[SelectedFilterType.AROUND_RADIUS]?.value || DEFAULT_AROUND_RADIUS;
    const hitsPerPage = this.store.pagination.itemsPerPage || undefined;
    const location = filtersRecord[SelectedFilterType.LOCATION]?.value || undefined;
    const page = this.store.pagination.page;
    const query = filtersRecord[SelectedFilterType.KEYWORD]?.value || '';

    const filters = this.store.selectedFilters.filter((f) =>
      [
        SelectedFilterType.BRAND,
        SelectedFilterType.JOB_TITLE,
        SelectedFilterType.KNOWLEDGE_DISCIPLINE,
        SelectedFilterType.LAST_ACTIVE,
        SelectedFilterType.OBJECT_ID,
        SelectedFilterType.TECHNOLOGY,
        SelectedFilterType.YEARS_OF_EXPERIENCE,
      ].includes(f.type),
    );

    const mappedFilters: BackendSelectedFilter[] = filters.reduce((acc, filter) => {
      const toAdd = [];

      // The backend requires the value to be in the `id` property.
      if (filter.type === SelectedFilterType.LAST_ACTIVE) {
        toAdd.push({ type: filter.type, id: filter.value, value: filter.value });
      }

      // The backend requires a separate filter for each years of experience.
      if (filter.type === SelectedFilterType.YEARS_OF_EXPERIENCE) {
        const filters = filter.value.map((v) => ({ type: filter.type, id: v, value: v }));
        toAdd.push(...filters);
      }

      if (toAdd.length === 0) {
        const value = typeof filter.value === 'number' ? filter.value : `${filter.value}`;

        toAdd.push({
          ...filter,
          value,
        });
      }

      return [...acc, ...toAdd];
    }, [] as BackendSelectedFilter[]);

    const search = {
      location,
      aroundRadius,
      page,
      hitsPerPage,
      query,
      filters: mappedFilters,
    };

    const employerId = this.meStore.employer?.id;
    const url = `/search-talent/employer/${employerId}/candidates`;

    const { data, error } = await useAtsCandidateDiscoveryApi(url)
      .post(search)
      .json<PaginatedResponse<SearchGatedUserProfile>>();

    if (error.value || !data.value) {
      throw new Error(error.value);
    }

    return data.value;
  }
}
