<script setup lang="ts">
import {
  type ConversationMessage,
  MessageMetadataType,
  type ScoreBlurbMetadata,
} from '@/core/conversations/message/conversation-message.type';
import { computed } from 'vue';
import DateUtils from '@/utils/date-utils';
import { onMounted } from 'vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import StarWithoutBgIcon from '@/assets/svg/conversations/star_without_bg.svg?component';
import ScoreChip from '@/components/Conversations/Application/ScoreChip.vue';
import IsMessageVisibleTooltip from '@/components/Conversations/Message/IsMessageVisibleTooltip.vue';
import { useWindowSize } from '@vueuse/core';
import CopilotChip from '@/components/Conversations/Message/CopilotChip.vue';
import CopilotAvatar from '@/components/Conversations/Avatar/CopilotAvatar.vue';

const props = defineProps<{
  message: ConversationMessage;
}>();

const { width } = useWindowSize();

const messageTime = computed(() => {
  return DateUtils.formatTimeAndDateIfNotToday(props.message.createdAt);
});

const windowWidth = computed(() => width.value);

const hideText = computed(() => windowWidth.value < 1280);
const startTruncatingText = computed(() => windowWidth.value <= 1280);

const score = computed(() => {
  return Number((props.message.metadata as ScoreBlurbMetadata)?.score) || 0;
});

const scoreColor = computed(() => {
  let color = 'critical';

  if (score.value >= 4) {
    color = 'primary';
  } else if (score.value >= 2) {
    color = 'caution';
  }

  const bubbleBorderColor = getBubbleBorderColor(color);

  return {
    starColor: `text-${color}-400`,
    bgColorText: `bg-${color}-0`,
    bgColorTitle: `bg-${color}-400`,
    bubbleBorderColor,
  };
});

const getBubbleBorderColor = (color: string) => {
  return color === 'primary' ? '#DEFAEA' : color === 'caution' ? '#FFF4D8' : '#FDE6E6';
};

onMounted(() => {
  TrackingService.trackAction(TrackingActionName.SCORE_SUMMARY_VIEWED, {
    source: 'Conversation Tab',
  });
});
</script>

<template>
  <div
    v-if="message.metadata?.type === MessageMetadataType.SCORE_BLURB && !!message.metadata.jobTitle"
    class="my-2 ml-9 flex items-end justify-end gap-1"
  >
    <div
      v-if="message.metadata?.scoreVersion === 'v1' || !message.metadata?.scoreVersion"
      class="copilot-message-gradient rounded-xl border border-highlight-50 p-4"
    >
      <div class="flex justify-between">
        <div class="flex items-center">
          <!-- CoPilot -->
          <CopilotChip />
          <!-- Message Time -->
          <span class="ml-1 mr-2 text-xs text-shade-800">{{ messageTime }}</span>
        </div>

        <!-- Visible only to you -->
        <div class="flex items-center">
          <img src="@/assets/svg/conversations/eye.svg" class="h-4 w-4" />
          <div class="text-xs font-semibold italic text-shade-800">Visible only to you</div>
        </div>
      </div>

      <div
        class="mt-1 inline-flex items-center rounded bg-highlight-0 px-1 py-0.5 font-sans text-sm"
      >
        <!-- Score -->
        <div class="cursor flex items-center space-x-0.5 rounded-sm bg-primary-300 px-1 py-0.5">
          <StarWithoutBgIcon alt="Star" class="h-3 w-3 text-primary-800" />
          <div class="text-xs font-semibold">
            {{ message.metadata?.score }}
          </div>
        </div>

        <!-- Job Title -->
        <div class="ml-2 mr-1 text-xs text-shade-880">for</div>
        <span class="text-xs font-bold text-shade-880">{{ message.metadata.jobTitle }}</span>
      </div>

      <!-- Message Content -->
      <div class="mt-1 text-sm text-shade-880" v-html="message.content"></div>

      <!-- Legacy score warning -->
      <div>
        <p class="text-[10px] font-normal italic leading-[21px] tracking-[0.015px] text-shade-800">
          This candidate was scored before the release of our updated scoring system.
        </p>
      </div>
    </div>

    <div
      v-else
      class="rounded-xl rounded-br border border-solid p-4"
      :class="[scoreColor.bgColorText]"
      :style="{ borderColor: scoreColor.bubbleBorderColor }"
    >
      <div class="flex justify-between">
        <div class="flex items-center gap-2">
          <!-- CoPilot -->
          <CopilotChip />

          <!-- Score and job title -->
          <div
            class="flex h-6 items-center gap-1.5 overflow-hidden text-ellipsis rounded-sm text-shade-880"
            :class="scoreColor.bgColorTitle"
            :style="{ padding: '2px 4px 2px 2px' }"
          >
            <span
              class="flex h-5 flex-row items-center gap-0.5 rounded-sm bg-white p-1 text-xs font-semibold"
            >
              <StarWithoutBgIcon alt="Star" class="h-3 w-3" :class="scoreColor.starColor" />
              {{ message.metadata?.score }}
            </span>
            <span class="text-xs font-normal text-shade-900">for</span>
            <span
              class="overflow-hidden text-ellipsis whitespace-nowrap text-xs font-bold text-shade-900"
              >{{ message.metadata.jobTitle }}
            </span>
          </div>
        </div>

        <!-- Visible only to you -->
        <div class="flex items-center">
          <IsMessageVisibleTooltip
            :truncate-text="startTruncatingText"
            :collapse-text="hideText"
            :text="'Visible only to you'"
          />
        </div>
      </div>

      <!-- Message Content -->
      <div class="score-summary ml-4 mt-3 text-sm text-shade-880" v-html="message.content"></div>

      <!-- Score Chips -->
      <div class="mt-3 flex flex-[1_0_0] flex-wrap content-start items-start gap-2">
        <ScoreChip
          v-for="chip in message.metadata?.chips"
          :key="chip.title"
          :chipTitle="chip.title"
          :chipText="chip.text"
          :color="chip.color"
          :popup-location="'top'"
          :should-show-dialog="true"
          analytics-source="Conversation Tab"
          :score="score"
          :application-id="message.metadata?.applicationId"
        />
      </div>
    </div>

    <!-- Copilot User Bubble -->
    <CopilotAvatar />
  </div>
</template>

<style scoped>
.copilot-message-gradient {
  background: radial-gradient(
    90.71% 100% at 50% 100%,
    rgba(236, 234, 253, 0.6) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
}
</style>

<style lang="postcss">
.score-summary {
  ul {
    @apply list-disc !important;
  }
}
</style>
