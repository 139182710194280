<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import VuePdfEmbed, { type VuePdfEmbedMethods } from 'vue-pdf-embed';
import { useResizeObserver } from '@vueuse/core';

import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import DownloadResumeButton from '@/components/Conversations/Resume/DownloadResumeButton.vue';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { ErrorService } from '@/core/shared/errors/error.service';
import { debounce, sleep } from 'radash';

const proProfileService = new ProProfileService();

const isLoading = ref(true);
const fileRef = ref<VuePdfEmbedMethods | null>(null);
const pdfWidth = ref(0);

const proProfileResumeUrl = computed(() => proProfileService.selectedProProfile?.pro?.resumeUrl);

const downloadResume = () => {
  if (!fileRef.value) {
    return;
  }

  try {
    const { nameFirst = '', nameLast = '' } = proProfileService.selectedProProfile || {};
    const name = `${nameFirst}_${nameLast}_resume`;
    fileRef.value.download(name);
    TrackingService.trackAction(TrackingActionName.RESUME_TAB_RESUME_DOWNLOADED);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to download resume. Please try again later.');
  }
};

function handleError() {
  isLoading.value = false;
  SnackbarService.critical('Error loading resume. Please try again later.');
}

function _setupPdfDimensions() {
  const pdfWrapper = document.getElementById('pdf-wrapper');

  if (pdfWrapper) {
    pdfWidth.value = pdfWrapper.offsetWidth;
  }
}
const setupPdfDimensions = debounce({ delay: 300 }, _setupPdfDimensions);

watch(proProfileResumeUrl, (newValue) => {
  if (!newValue) {
    isLoading.value = false;
  }
});

onMounted(async () => {
  setupPdfDimensions();

  // observe pdf wrapper for resizes
  const pdfWrapper = document.getElementById('pdf-wrapper');
  useResizeObserver(pdfWrapper, setupPdfDimensions);

  // let's wait some milliseconds before hiding skeleton loader if there is no resume
  await sleep(250);

  if (!proProfileResumeUrl.value) {
    isLoading.value = false;
  }
});
</script>

<template>
  <div class="resume h-full w-full overflow-y-scroll p-4">
    <div v-if="proProfileResumeUrl" class="relative border border-tint-60" id="pdf-wrapper">
      <Transition name="fade">
        <div v-if="pdfWidth">
          <DownloadResumeButton
            class="absolute z-50 float-right mr-4 mt-4"
            @click="downloadResume"
          />
          <VuePdfEmbed
            :source="proProfileResumeUrl"
            ref="fileRef"
            :width="pdfWidth"
            @rendered="isLoading = false"
            @loading-failed="handleError"
            @rendering-failed="handleError"
          />
        </div>
      </Transition>
    </div>

    <template v-else>
      <template v-if="isLoading">
        <v-skeleton-loader boilerplate type="article, subtitle, article, article, paragraph" />
      </template>
      <template v-else>
        <div class="flex h-full flex-col items-center justify-center">
          <img src="@/assets/svg/no-resume.svg" alt="No resume" />
          <p class="mb-2 mt-4 text-sm font-bold text-shade-800">No resume available</p>
          <p class="w-60 text-center text-sm text-shade-800">
            This candidate doesn't<br />have resume yet.
          </p>
        </div>
      </template>
    </template>
  </div>
</template>
